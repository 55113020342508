<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <!-- <el-button type="primary" :disabled="!checkedCompany.pcId || true">导出研发项目表</el-button> -->
            </div>
        </div>
        <div class="showModelContent">
            <div class="wordSetContent">
                <div class="modelHeader">
                    <div class="flex-a-b-c modelHeaderTitle mr30 ml30">
                        <div class="flex-a-c ">
                            <!-- <el-input v-model="formData.name" class="w130" placeholder="公司名"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.companyName }}</div>
                            <span class="ml20">管理文件>科技成果转化奖励 V1.0</span>
                        </div>
                        <div class="flex-a-c">
                            <span>编号：</span>
                            <!-- <el-input v-model="formData.name" class="w70" placeholder="编号"></el-input> -->
                            <div class="zdhq">{{ checkedCompany.shortName }}</div>
                            <span>-HR-R004</span>
                        </div>
                    </div>
                    <div class="flex-a-b-c mb10">
                        <div class="squareLeft"></div>
                        <div class="squareRight"></div>
                    </div>
                </div>
                <el-image class="companyLogo mt20" v-if="checkedCompany.logo" :src="checkedCompany.logo"></el-image>
                <div class="pl30 pr30 wordSetContent">
                    <div class="textc pjTitle mt10 mb20 fs30">科技成果转化奖励签收单</div>
                    <div class="text-align-right fs10">时间：<el-date-picker v-model="formData.fileDay"
                            class="dayInput w230" value-format="yyyy-MM-dd" format="yyyy年MM月dd日" type="date"
                            placeholder="选择日期">
                        </el-date-picker></div>
                    <table border="1" cellspacing="0" class="tableBorder institutionTable">
                        <thead>
                            <tr class="h60 fwbold">
                                <td class="textc itemTitle w80 fs9">序号</td>
                                <td class="textc itemTitle w300 fs9">科技成果名称</td>
                                <td class="textc itemTitle fs9">主要参与人</td>
                                <td class="textc itemTitle w150 fs9">金额（元）</td>
                                <td class="textc itemTitle fs9 w150">签收人</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="h60" v-for="(row, index) in formData.content" :key="index">
                                <td class="textc itemContent">{{ row.num }}</td>
                                <td class="itemContent">
                                    <el-input v-model="row.name" class="wordInput" placeholder="科技成果名称"></el-input>
                                </td>
                                <td class="textc itemContent">
                                    <el-input v-model="row.joinName" class="wordInput" placeholder="主要参与人"></el-input>
                                </td>
                                <td class="itemContent">
                                    <el-input v-model="row.money" class="wordInput" placeholder="金额（元）"></el-input>
                                </td>
                                <td class="textc itemContent">
                                    <!-- <el-input v-model="row.signName" class="wordInput" placeholder="签收人"></el-input> -->
                                    <div class="tool d-flex">
                                        <div>
                                            <i class="eicon el-icon-caret-top" @click="topData(index)" title="上移"></i>
                                            <i class="eicon el-icon-caret-bottom" @click="bottomData(index)"
                                                title="下移"></i>
                                        </div>
                                        <div>
                                            <i class="eicon el-icon-plus" @click="addData()" title="添加"></i>
                                            <i class="eicon el-icon-delete" @click="delData(index)" title="删除"></i>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="h60">
                                <td colspan="3" class="textc fs9">
                                    合 计
                                </td>
                                <td class="textc fs9">
                                    {{ sumNumber }}
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="wordPage flex-a-b-c">
                <div class="squareLeft"></div>
                <div>第 1 页 共 1 页</div>
                <div class="squareRight"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { getProjectRulesData } from "@/api/projectAdmin";
export default {
    name: "institution93122",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        },
        isAgain: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formData: {
                content: [],
                fileDay: ""
            },
            checkedCompany:{},
            showAddData: false,
            visible: true,
        };
    },
    computed: {
        sumNumber() {
            let sum = 0;
            if (this.formData.content && this.formData.content.length > 0) {
                for (let row of this.formData.content) {
                    if (row.money) {
                        sum += Number(row.money);
                    }
                }
            }
            return sum.toFixed(2);
        }
    },
    watch: {
        wordData: {
            handler(data) {
               //-console.log(data);
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData = {
                            content: data.content ? data.content : [{
                                num: 1,
                                name: "",
                                joinName: "",
                                money: 0,
                                signName: ""
                            }],
                            fileDay: data.fileDay
                        };
                    } else {
                        this.formData = {
                            content: [{
                                num: 1,
                                name: "",
                                joinName: "",
                                money: 0,
                                signName: ""
                            }],
                            fileDay: this.checkedCompany.fileDay
                        };
                        this.addListData();
                    }
                }
            },
        },
    },
    created() {

    },
    methods: {
        addListData() {
            getProjectRulesData({
                codeStr: "9.3.1.1-2",
                year: this.yearValue,
                isAgain: this.isAgain,
                pcId: this.checkedCompany.pcId,
            }).then(res => {
                if (res.data) {
                    this.formData.content=[{
                        num: 1,
                        name: res.data[0].content ? res.data[0].content : "",
                        joinName: res.data[0].joinName ? res.data[0].joinName : "",
                        money: res.data[0].money ? res.data[0].money : 0,
                        signName: ""
                    }];
                    this.getTableNum();
                }
            })
        },
        /**@method 添加 */
        addData() {
            this.formData.content.push({
                num: 1,
                name: "",
                joinName: "",
                money: 0,
                signName: ""
            });

            this.getTableNum();
        },
        getTableNum() {
            for (let i in this.formData.content) {
                this.$set(this.formData.content[i], "num", Number(i) + 1);
            }
        },
        delData(i) {
            if (this.formData.content.length > 1) {
                this.formData.content.splice(i, 1)
            }
            this.getTableNum();
        },
        bottomData(i) {
            if (this.formData.content.length != (i + 1)) {
                let bottomData = JSON.parse(JSON.stringify(this.formData.content[i + 1]));
                let currentData = JSON.parse(JSON.stringify(this.formData.content[i]));
                this.$set(this.formData.content, i, bottomData);
                this.$set(this.formData.content, i + 1, currentData);
            }
            this.getTableNum();
        },
        topData(i) {
            if (i > 0) {
                let topData = JSON.parse(JSON.stringify(this.formData.content[i - 1]));
                let currentData = JSON.parse(JSON.stringify(this.formData.content[i]));
                this.$set(this.formData.content, i, topData);
                this.$set(this.formData.content, i - 1, currentData);
            }
            this.getTableNum();
        },
    },
};
</script>

<style lang="scss" scoped>
.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.institutionTable {
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }

    .tool {
        display: block;
        position: absolute;
        top: 50%;
        right: -51px;
        width: 50px;
        height: 50px;
        margin-top: -25px;
        background: #f1f2f4;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

        .eicon {
            font-size: 18px;
            color: #666;
            cursor: pointer;
            height: 25px;
            width: 25px;
            text-align: center;
            line-height: 25px;
        }

        .el-icon-delete {
            color: #F56C6C;
        }
    }

    .itemTitle {
        height: 100%;
        font-size: 15px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 15px;
        position: relative;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    font-size: 20px;
    letter-spacing: 3px;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }
}

.showModelContent {
    background: #fff;
    position: relative;
    width: 1100px;
    min-height: 1500px;
    margin: 0 10px;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;

            div {
                font-size: 15px;
                color: #999;
            }

            span {
                color: #999;
                font-size: 15px;
            }
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordSetContent {
        .wordInput {
            /deep/input {
                font-size: 20px !important;
                line-height: 20px;
            }
        }
    }
}
</style>