<template>
    <div class="view">
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;" class="d-flex">
                <el-button size="small" type="text" class="mr10" @click="downloadModel">下载模板</el-button>
                <el-upload class="institution23 upload-demo" action="#" :show-file-list="false" :auto-upload="false"
                    :on-change="handleUpload" accept=".xlsx,.xls">
                    <el-button size="small" type="primary">导入明细数据</el-button>
                </el-upload>
            </div>
        </div>
        <div class="showModelContent">
            <div class="pl30 pr30 wordSetContent">
                <div class="flex-c-c textc mt20">
                    <!-- <el-input v-model="formData.name" class="fs30 pjName w100p" placeholder="项目名称"></el-input> -->
                    <div class="zdhq fs35">{{ checkedCompany.companyName }}</div>
                </div>
                <div class="textc pjTitle mt10 mb20 fs30">研发设备明细表</div>
                <table border="1" cellspacing="0" class="tableBorder institutionTable">
                    <thead>
                        <tr class="h40 fwbold">
                            <td class="textc itemTitle w80">序号</td>
                            <td class="textc itemTitle w300">设备名称</td>
                            <td class="textc itemTitle">型号或规格</td>
                            <td class="textc itemTitle w110">数量</td>
                            <td class="textc itemTitle">用途</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="h40" v-for="(row, index) in formData.content" :key="index">
                            <td class="textc itemContent">{{ row.num }}</td>
                            <td class="itemContent">
                                <el-input v-model="row.name" class="wordInput" placeholder="设备名称"></el-input>
                            </td>
                            <td class="textc itemContent">
                                <el-popover placement="top" width="190" trigger="click" v-for="(child, key) in row.data"
                                    :key="key + 'a'">
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text"
                                            @click="delSpecsFun(index, key)">删除规格</el-button>
                                        <el-button type="primary" size="mini"
                                            @click="addSpecsFun(index)">添加规格</el-button>
                                    </div>
                                    <div slot="reference">
                                        <el-input v-model="child.type" :class="{ 'bt000': key != 0 }" class="wordInput"
                                            placeholder="型号/规格"></el-input>
                                    </div>
                                </el-popover>
                            </td>
                            <td class="itemContent">
                                <el-popover placement="top" width="190" trigger="click" v-for="(child, key) in row.data"
                                    :key="key + 'b'">
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="text"
                                            @click="delSpecsFun(index, key)">删除规格</el-button>
                                        <el-button type="primary" size="mini"
                                            @click="addSpecsFun(index)">添加规格</el-button>
                                    </div>
                                    <div slot="reference">
                                        <div class="flex-a-c" :class="{ 'bt000': key != 0 }">
                                            <el-input v-model="child.size" type="text" class="wordInput"
                                                placeholder="数量"></el-input>
                                        </div>
                                    </div>
                                </el-popover>

                            </td>
                            <td class="textc itemContent">
                                <el-input v-model="row.use" type="textarea" class="wordInput" placeholder="用途"
                                    :autosize="{ minRows: 2 }"></el-input>
                                <div class="tool d-flex">
                                    <div>
                                        <i class="eicon el-icon-caret-top" @click="topDevice(index)" title="上移设备"></i>
                                        <i class="eicon el-icon-caret-bottom" @click="bottomDevice(index)"
                                            title="下移设备"></i>
                                    </div>
                                    <div>
                                        <i class="eicon el-icon-plus" @click="addDevice()" title="添加设备"></i>
                                        <i class="eicon el-icon-delete" @click="delDevice(index)" title="删除设备"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import * as XLSX from 'xlsx';
export default {
    name: "institution9223",
    props: {
        yearValue: {
            type: String,
            default: ""
        },
        wordData: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data() {
        return {
            showAddDevice: false,
            formData: {
                content: [
                    {
                        num: 1,
                        name: "",
                        use: "",
                        data: [
                            {
                                type: "",
                                size: "",
                            }
                        ]
                    },
                ]
            },
            showImport: false,
            checkedCompany:{},
        };
    },
    watch: {
        wordData: {
            handler(data) {
                if (data && JSON.stringify(data) != '{}') {
                    this.checkedCompany = data.companyData;
                    if (data.rulesId) {
                        this.formData.content = data.content ? data.content : [{
                            num: 1,
                            name: "",
                            use: "",
                            data: [
                                {
                                    type: "",
                                    size: "",
                                }
                            ]
                        },];
                    } else {
                        this.formData.content = [{
                            num: 1,
                            name: "",
                            use: "",
                            data: [
                                {
                                    type: "",
                                    size: "",
                                }
                            ]
                        },];
                    }
                }
            },
            deep: true,
            immediate: true
        },
    },
    created() {

    },
    methods: {
        downloadModel(){
            location.href=this.$store.state.apiUrl+"/crmPc/project/downloadProjectMoney?fileType=0&isRules=true"
        },
        handleUpload(fileData) {
            const file = fileData.raw;
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                let excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
               //-console.log(excelData);
                if (excelData[3] && excelData[3].length > 0) {
                    let titleData = ['序号', '设备名称', '型号或规格', '数量', '用途'];
                    let titleArr = [];
                    let open = false;
                    let num = 1;
                    let list = [];
                    for (let row of excelData) {
                        if (row && row.length > 0) {
                            if (open) {
                                if (row[titleArr.indexOf(titleData[0])]) {//获取序号下标
                                    list.push({
                                        num: num,
                                        name: row[titleArr.indexOf(titleData[1])],
                                        use: row[titleArr.indexOf(titleData[4])],
                                        data: [
                                            {
                                                type: row[titleArr.indexOf(titleData[2])],
                                                size: row[titleArr.indexOf(titleData[3])],
                                            }
                                        ]
                                    },)
                                } else {
                                    list[list.length - 1].data.push({
                                        type: row[titleArr.indexOf(titleData[2])],
                                        size: row[titleArr.indexOf(titleData[3])],
                                    })
                                }
                                num++;
                            } else if (row.indexOf(titleData[0]) != -1) {
                                titleArr = row;
                                open = true;
                            }
                        }

                    }
                    this.formData.content = list;
                }
            };
            reader.readAsArrayBuffer(file);
        },
        showImportModel() {
            this.showImport = true;
        },
        /**@method 添加设备 */
        addDevice() {
            this.formData.content.push({
                num: 1,
                name: "",
                use: "",
                data: [
                    {
                        type: "",
                        size: "",
                    }
                ]
            });
            this.getTableNum();
        },
        getTableNum() {
            for (let i in this.formData.content) {
                this.$set(this.formData.content[i], "num", Number(i) + 1);
            }
        },
        delDevice(i) {
            if (this.formData.content.length > 1) {
                this.formData.content.splice(i, 1)
            }
            this.getTableNum();
        },
        /**@method 删除规格 */
        delSpecsFun(i, key) {
            if (this.formData.content[i].data.length > 1) {
                this.formData.content[i].data.splice(key, 1)
            }
            this.getTableNum();
        },
        bottomDevice(i) {
            if (this.formData.content.length != (i + 1)) {
                let bottomData = JSON.parse(JSON.stringify(this.formData.content[i + 1]));
                let currentData = JSON.parse(JSON.stringify(this.formData.content[i]));
                // this.tableData[i] = bottomData;
                // this.tableData[i + 1] = currentData;
                this.$set(this.formData.content, i, bottomData);
                this.$set(this.formData.content, i + 1, currentData);
            }
            this.getTableNum();
        },
        topDevice(i) {
            if (i > 0) {
                let topData = JSON.parse(JSON.stringify(this.formData.content[i - 1]));
                let currentData = JSON.parse(JSON.stringify(this.formData.content[i]));
                // this.tableData[i] = topData;
                // this.tableData[i - 1] = currentData;
                this.$set(this.formData.content, i, topData);
                this.$set(this.formData.content, i - 1, currentData);
            }
            this.getTableNum();
        },
        addSpecsFun(i) {
            this.formData.content[i].data.push({
                specs: "",
                deviceNumber: "",
            });
            this.getTableNum();
        },
    },
};
</script>
<style lang="scss">
.institution23 {
    .el-upload--text {
        width: auto !important;
        height: auto !important;
        border: none !important;
    }
}
</style>
<style lang="scss" scoped>
.companyLogo {
    height: 80px;
    position: absolute;
    top: 80px;
    left: 80px;
}

.institutionTable {
    border-color: #000;

    /deep/input {
        border: none;
        color: #E6A23C;
    }

    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
        border: none;
        color: #E6A23C;
        text-align: justify;
    }

    .tool {
        display: block;
        position: absolute;
        top: 50%;
        right: -51px;
        width: 50px;
        height: 50px;
        margin-top: -25px;
        background: #f1f2f4;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);

        .eicon {
            font-size: 18px;
            color: #666;
            cursor: pointer;
            height: 25px;
            width: 25px;
            text-align: center;
            line-height: 25px;
        }

        .el-icon-delete {
            color: #F56C6C;
        }
    }

    .itemTitle {
        height: 100%;
        font-size: 15px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        height: 100%;
        font-size: 15px;
        position: relative;
    }
}

.showModelContent div,
.showModelContent span {
    color: #000;
    border-color: #000;
    letter-spacing: 3px;
}

.showModelContent {
    background: #fff;
    position: relative;
    // width: 1100px;
    // min-height: 1500px;
    margin: 0 10px;
    // box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 50px;
    padding-bottom: 100px;

    .modelHeader {

        .modelHeaderTitle {
            border-bottom: 1px solid #000;
        }

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-top: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }

    .wordPage {
        position: absolute;
        bottom: 50px;
        width: 1000px;
        height: 30px;
        font-size: 20px;

        .squareLeft {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-left: none;
            width: 30px;
            height: 30px;
        }

        .squareRight {
            border: 1px solid #c4c4c4;
            border-bottom: none;
            border-right: none;
            width: 30px;
            height: 30px;
        }
    }
}
</style>