<template>
  <div class="listswitchingstyle">
    <!-- 标题 -->
    <h2>{{ datas.text }}</h2>

    <!-- 表单 -->
    <el-form label-width="80px" :model="datas" size="small" :rules="rules">
      <div class="moditystyle moditystyle2" v-show="datas.commoditylisttype === 0">
        <span class="addMethod" :class="datas.addMethod == 'auto' ? 'active' : ''" @click="datas.addMethod = 'auto'">
          自动获取商品
        </span>
        <span class="addMethod" :class="datas.addMethod == 'manual' ? 'active' : ''" @click="datas.addMethod = 'manual'">
          手动添加商品
        </span>
      </div>
      <div class="bor" v-show="datas.commoditylisttype === 0"></div>
      <!-- 标题内容 -->
      <el-form-item class="lef" label="选择模板" v-show="datas.commoditylisttype !== 2">
        <p style="color: #000">{{ styleText }}</p>
      </el-form-item>

      <!-- 商品样式选择 -->
      <div class="commodityType" v-show="datas.commoditylisttype !== 2">
        <el-tooltip class="item" effect="dark" :content="item.content" placement="bottom"
          v-for="(item, index) in commodityTypes" :key="index">
          <span class="iconfont" style="font-size: 21px" :class="[
            item.type === datas.commodityType ? 'active' : '',
            item.icon,
          ]" @click="checkedType(item)" />
        </el-tooltip>
      </div>

      <!-- 下划线 -->
      <div class="bor" v-show="datas.commoditylisttype !== 2" />
      <!-- 商品类型选择 -->
      <el-form-item label="商品类型" class="lef mt10">
        <el-radio-group v-model="datas.commoditylisttype">
          <el-radio :label="0">默认</el-radio>
          <!-- <el-radio :label="1">顶部分组</el-radio> -->
          <el-radio :label="2">左侧分组</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="商品分类" class="lef mt10" v-if="datas.commoditylisttype !== 2">
        <el-cascader v-model="prodTypeValue" :props="{ expandTrigger: 'hover' }" :options="prodTypeData"
          @change="changeProdType"></el-cascader>
      </el-form-item>

      <!-- tabbar颜色 -->
      <el-form-item label="标签颜色" class="lef" v-show="datas.commoditylisttype !== 0">
        <el-color-picker v-model="datas.tabColor" show-alpha class="picke" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>
      <div v-show="datas.commoditylisttype === 2">
        <el-divider content-position="left">广告图</el-divider>
        <p style="color: #969799;font-size: 12px;margin-left: 7px;margin-top: 10px;">
          鼠标拖拽调整广告图顺序
        </p>
        <el-button @click="$refs.multopleUpload.showUpload()" class="w100p mt10" type="primary" plain>
          <i class="el-icon-plus" />添加广告图
        </el-button>
        <vuedraggable v-model="datas.bannerData" v-bind="dragOptions">
          <transition-group>
            <section class="imgBanner" v-for="(item, index) in datas.bannerData" :key="index + 'b'">
              <i class="el-icon-circle-close" @click="deleteBannerImg(index)" />
              <!-- 图片 -->
              <div class="imag">
                <el-image style="width: 100%; height: 100%" :src="item.imageUrl" fit="cover"></el-image>
              </div>
              <!-- 标题和链接 -->
              <div class="imgText">

                <!-- 选择类型 -->
                <parkJumpLink :linkData="item.http" @changeFun="jumpLinkChange($event, index)" />
              </div>
            </section>
          </transition-group>
        </vuedraggable>
      </div>

      <div v-show="datas.commoditylisttype === 0">
        <h5 style="color: #000; font-size: 14px">基础设置</h5>
        <br>
        <el-form-item label="商品标题" class="lef">
          <el-input v-model="datas.name"></el-input>
        </el-form-item>
        <el-form-item class="lef" label="显示方式" v-if="datas.commodityType == 5">
          <el-radio v-model="datas.showType" :label="0">拖动显示</el-radio>
          <el-radio v-model="datas.showType" :label="1">换行显示</el-radio>
        </el-form-item>
        <div v-if="datas.addMethod == 'auto'">
          <el-form-item class="lef" label="列表类型"
            v-if="titleType === 2 && datas.commodityType != 5 && datas.commodityType != 1">
            <el-radio v-model="datas.isPage" :label="true" @change="changeIsPage">拖动分页</el-radio>
            <el-radio v-model="datas.isPage" :label="false" @change="changeIsPage">固定条数</el-radio>
          </el-form-item>
          <el-form-item label="商品条数" class="lef borrediu" v-if="!datas.isPage || titleType !== 2">
            <el-slider v-model="datas.dataNumber" @change="changeDataNumber($event)" :max="10" :min="1" input-size="mini"
              show-input>
            </el-slider>
          </el-form-item>
        </div>
        <div v-if="datas.addMethod == 'manual'">
          <h5 style="color: #000; font-size: 14px">添加商品</h5>
          <br>
          <!-- 上传图片 -->
          <el-button @click="dialogVisibleshow('imageList', null)" class="uploadImg" type="primary" plain><i
              class="el-icon-plus" />点击添加商品</el-button>
          <p style="color: #969799; font-size: 12px; margin-top: 10px">
            鼠标拖拽调整商品顺序
          </p>
          <div v-if="datas.manualData[0]">
            <vuedraggable v-model="datas.manualData" v-bind="dragOptions">
              <transition-group>
                <section class="imgBanner" v-for="(item, index) in datas.manualData" :key="index + 'a'">
                  <i class="el-icon-circle-close" @click="deleteimg(index)" />
                  <div class="imag">
                    <img draggable="false" :src="item.coverUrl" alt="" />
                  </div>
                  <div class="imgText">
                    <!-- <div>
                    <el-input disabled="disabled" style="width: 65%" v-model="item.name" size="mini" />
                    <el-input disabled="disabled" type="number" style="width: 35%" v-model.number="item.price"
                      size="mini" />
                  </div>
                  <el-input disabled="disabled" v-model.number="item.introduce" size="mini" /> -->
                    <el-input disabled="disabled" v-model.number="item.name" placeholder="商品名称" size="mini" />
                    <el-input disabled="disabled" class="suffixInput" v-model.number="item.price" placeholder="商品价格"
                      size="mini">
                      <template slot="append">元</template>
                    </el-input>
                  </div>
                </section>
              </transition-group>
            </vuedraggable>
          </div>

        </div>
      </div>

      <!-- <div style="height: 10px" /> -->

      <!-- 商品样式 -->
      <!-- <el-form-item label="商品样式" class="lef" /> -->
      <!-- 商品样式选择 -->
      <!-- <div class="moditystyle">
        <span v-for="(item, index) in moditystyles" :key="index" :class="item.type == datas.moditystyle ? 'active' : ''"
          @click="datas.moditystyle = index">
          {{ item.text }}
        </span>
      </div> -->

      <!-- <div class="bor" /> -->

      <!-- 显示位置 -->
      <!-- <el-form-item label="显示位置" class="lef">
        <div class="weiz">
          <i :class="datas.positions === 'left' ? 'active' : ''" class="iconfont icon-horizontal-left"
            @click="datas.positions = 'left'" />
          <i :class="datas.positions === 'center' ? 'active' : ''" class="iconfont icon-juzhong"
            @click="datas.positions = 'center'" />
        </div>
      </el-form-item>

      <div style="height: 10px" /> -->

      <!-- 文本粗细 -->
      <!-- <el-form-item class="lef" label="文本粗细" prop="textWeight" :hide-required-asterisk="true">
        <el-input type="number" v-model.number="datas.textWeight" placeholder="请输入文本粗细" />
      </el-form-item>

      <div style="height: 10px" /> -->

      <!-- 图片倒角 -->
      <el-form-item label="边框倒角" class="lef borrediu" v-if="datas.commoditylisttype === 0">
        <el-slider v-model="datas.borderRadius" :max="30" input-size="mini" show-input>
        </el-slider>
      </el-form-item>

      <div style="height: 10px" />

      <!-- 页面边距 -->
      <!-- <el-form-item class="lef" label="页面边距">
        <el-slider v-model="datas.pageMargin" :max="20" input-size="mini" show-input>
        </el-slider>
      </el-form-item> -->

      <!-- <div style="height: 10px" /> -->

      <!-- 商品间距 -->
      <el-form-item class="lef" label="商品间距" v-if="datas.commodityType != 4 && datas.showType != 1">
        <el-slider v-model="datas.commodityMargin" :max="20" input-size="mini" show-input>
        </el-slider>
      </el-form-item>

      <div style="height: 10px" v-if="datas.commodityType != 4 || datas.commoditylisttype !== 0" />

      <el-form-item class="lef" label="背景图片" v-if="datas.commoditylisttype === 0">
        <div class="shop-head-pic" style="text-align: center">
          <img class="home-bg" :src="datas.bgImg" alt="" v-if="datas.bgImg" />
          <div class="shop-head-pic-btn" style="text-align: center">
            <el-button @click="showUpload('0')" class="uploadImg" type="primary" plain><i class="el-icon-plus" />更换图片
            </el-button>
            <el-button type="primary" class="uploadImg" @click="clear()">清空图片</el-button>
          </div>
        </div>
      </el-form-item>
      <div style="height: 10px" />
      <el-form-item class="lef" label="更多" v-if="datas.commoditylisttype === 0">
        {{ datas.showMore ? '显示' : '隐藏' }}
        <el-checkbox style="margin-left: 196px" v-model="datas.showMore" />
      </el-form-item>
      <!--商品价格 -->
      <el-form-item class="lef" label="商品价格">
        {{ datas.priceofcommodity ? '显示' : '隐藏' }}
        <el-checkbox style="margin-left: 196px" v-model="datas.priceofcommodity" />
      </el-form-item>

      <!--购买按钮 -->
      <el-form-item class="lef" label="购买按钮">
        {{ datas.purchasebutton ? '显示' : '隐藏' }}
        <el-checkbox style="margin-left: 196px" v-model="datas.purchasebutton" />
      </el-form-item>

      <el-radio-group v-model="datas.purchasebuttonType" class="radi1" v-show="datas.purchasebutton">
        <el-radio :label="index - 1" v-for="index in 8" :key="index">样式{{ index }}</el-radio>

        <el-input v-show="datas.purchasebuttonType > 3" style="width: 40%; margin-top: 10px" v-model="datas.purchase"
          size="mini" />
      </el-radio-group>

      <div style="height: 10px" />

      <!--商品角标 -->
      <el-form-item class="lef" label="商品角标">
        {{ datas.commoditycorner ? '显示' : '隐藏' }}
        <el-checkbox style="margin-left: 196px" v-model="datas.commoditycorner" />
      </el-form-item>

      <el-radio-group v-model="datas.commoditycornertype" class="radi1" v-show="datas.commoditycorner">
        <el-radio :label="index" v-for="(item, index) in marker" :key="index">{{
          item
        }}</el-radio>
      </el-radio-group>

      <el-form-item class="lef" label="颜色">
        <el-color-picker v-model="datas.commodityTagColor" show-alpha class="picke" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>

      <el-form-item class="lef" label="位置">
        <el-radio-group v-model="datas.tagPosition" class="radi1">
          <el-radio :label="index" v-for="(item, index) in tagPosition" :key="index">{{ item }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <!-- 上传商品 -->
    <uploadCommodity ref="upload" @uploadListInformation="uploadListInformation" />
    <!-- 上传图片 -->
    <uploadimg ref="uploadImg" @uploadInformation="uploadInformation" />
    <!-- 上传分组轮播图 -->
    <uploadMultipleImg ref="multopleUpload" @uploadInformation="uploadMultopleInformation" />
  </div>
</template>

<script>
import { getGoodsListByLink } from "@/api/appModel";
import uploadCommodity from '../../../uploadParkCommodity' //图片上传
import uploadimg from '../../../uploadImg' //图片上传
import uploadMultipleImg from '../../../uploadMultipleImg' //图片上传
import vuedraggable from 'vuedraggable' //拖拽组件

export default {
  name: 'parkListswitchingstyle',
  props: {
    datas: Object,
    titleType: Number
  },
  components: { vuedraggable, uploadCommodity, uploadimg, uploadMultipleImg },
  data() {
    let kon = (rule, value, callback) => {
      if (value.length === 0) callback(new Error('请输入有效数字'))
    }
    return {
      productData: [],
      color1: '#07c160',
      moditystyles: [
        /* 商品样式 */
        {
          text: '无边白底',
          type: 0,
        },
        {
          text: '卡片投影',
          type: 1,
        },
        {
          text: '描边白底',
          type: 2,
        },
        {
          text: '无边透明底',
          type: 3,
        },
      ],
      commodityTypes: [
        {
          icon: 'icon-datumoshi',
          type: 0,
          content: '大图模式',
        },
        {
          icon: 'icon-commodity-yihangliangge',
          type: 1,
          content: '一行两个',
        },
        // {
        //   icon: 'icon-yihangsange',
        //   type: 2,
        //   content: '一行三个',
        // },
        {
          icon: 'icon-commodity-xiangxiliebiao',
          type: 3,
          content: '详细列表',
        },
        {
          icon: 'icon-icon_shangpintu_yidaliangxiao',
          type: 4,
          content: '一大两小',
        },
        {
          icon: 'icon-xuanzemokuai-daohanghengxianghuadong',
          type: 5,
          content: '横向滑动',
        },
      ],
      rules: {
        textWeight: [{ required: true, validator: kon, trigger: 'blur' }],
      },
      marker: ['新品', '热卖', 'NEW', 'HOT'],
      dragOptions: {
        animation: 200,
      },
      imgText: null, //当前选中的类型
      imgNumber: null, //第几个数组
      predefineColors: [
        // 颜色选择器预设
        "#fff",
        "#000",
        "#3064ba",
        '#51CDCB',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        '#409EFF',
        '#909399',
        '#C0C4CC',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577',
      ],
      options: [], // 更多跳转链接
      tagPosition: [
        // 标记位置
        '左上',
        '左下',
        '右上',
        '右下',
      ],
      uploadImgDataType: null,
      prodTypeData: [],
      prodTypeValue: [],
    }
  },
  created() {
    this.prodTypeValue = this.datas.prodType;
    this.productData = this.$store.state.parkDrag.productData;
    this.getGoodsListByLink();
    this.getPordType();
    if (this.datas.prodType) {
      this.changeProdType(["", this.datas.prodType]);
    }
  },
  methods: {
    changeProdType(data) {
      this.productData = [];
      let productData = [];
      this.datas.autoData = [];
      let typeProductData = this.$store.state.parkDrag.typeProductData;
      this.datas.prodType = data[1];
      if (data[0] == 'news') {
        this.productData = this.$store.state.parkDrag.productData;
        this.datas.autoData = this.productData.slice(0, this.datas.dataNumber)
        this.datas.prodType = 0;
      } else if (typeProductData[data[1]]) {
        this.productData = this.$store.state.parkDrag.typeProductData[data[1]];
        this.datas.autoData = this.productData.slice(0, this.datas.dataNumber)
      } else {
        getGoodsListByLink({
          pageSize: 10,
          accountType: 9,
          parkId: this.$store.state.checkedPark.parkId,
          goodstypeId: data[1],
          isPutOn: true
        }).then(res => {
          if (res.data) {
            for (let row of res.data.pageInfo.list) {
              productData.push({
                coverUrl: row.goodPhoto.split(",")[0],
                // type: val.goodstypeId,
                id: row.goodsId,
                name: row.goodsName,
                price: row.price / 100
              });
            }
          }
          this.$store.commit("setParkDragObjData", {
            parentKey: "typeProductData",
            key: data[1],
            list: productData
          });
          this.productData = this.$store.state.parkDrag.typeProductData[data[1]];
          this.datas.autoData = this.productData.slice(0, this.datas.dataNumber)
        }).catch(err => {
          this.$store.commit("setParkDragObjData", {
            parentKey: "typeProductData",
            key: data[1],
            list: productData
          });
          this.productData = this.$store.state.parkDrag.typeProductData[data[1]];
          this.datas.autoData = this.productData.slice(0, this.datas.dataNumber)
        })
      }
    },
    getPordType() {
      let prodTypeData = [];
      let maxTypeIds = [];
      let productTypeData = this.$store.state.parkDrag.productTypeData;
      for (let row of productTypeData) {
        if (row.dictLabel === '无') {
          row.dictLabel = "其他";
          row.dictValue = "other";
        }
       //-console.log(row);
        if (maxTypeIds.indexOf(row.dictValue) == -1) {
          maxTypeIds.push(row.dictValue);
          prodTypeData.push({
            value: row.dictValue,
            label: row.dictLabel,
            children: [{ value: row.id, label: row.text }],
          });
        } else {
          prodTypeData[maxTypeIds.indexOf(row.dictValue)].children.push({ value: row.id, label: row.text });
        }
      }
      prodTypeData.unshift({ value: "news", label: "最新商品" });
      this.prodTypeData = prodTypeData;
    },
    jumpLinkChange(data, index) {
      if (data.type) {
        let { urlType, type, id, title } = data;
        let obj = {
          urlType,
          type,
          id,
          title
        }
        this.datas.bannerData[index].http = obj
      } else {
        this.datas.bannerData[index].http = {};
      }
      this.$set(this.datas.bannerData, index, this.datas.bannerData[index])
    },
    checkedType(item) {
      this.datas.commodityType = item.type;
      this.datas.showType = 0;
      if (this.datas.commodityType == 4) {
        this.datas.commodityMargin = 10;
      }
    },
    /**@method 获取商品数据 */
    getGoodsListByLink() {
      this.datas.autoData = this.productData.slice(0, this.datas.dataNumber)
      for (let tyepArr of this.datas.commoditylisttypetab) {
        tyepArr.autoData = this.productData.slice(0, tyepArr.dataNumber)
      }
      let commoditylisttypetab = [];
      for (let row of this.$store.state.parkDrag.productGroupData) {
        commoditylisttypetab.push({
          typeId: row.typeId,
          text: row.name,
          autoData: row.productData
        });
      }
      this.datas.commoditylisttypetab = commoditylisttypetab;
    },
    changeIsPage(val) {
      if (val) {
        this.datas.dataNumber = 4;
        this.datas.autoData = this.productData.slice(0, this.datas.dataNumber)
      }
    },
    changeDataNumber(dataNumber) {
      this.datas.autoData = this.productData.slice(0, dataNumber)
    },
    /* 上传图片弹框 */
    dialogVisibleshow(text, number) {
      this.$refs.upload.showUpload()
      this.imgText = text
      this.number = number
    },
    // 提交
    async uploadListInformation(data) {
      if (this.imgText === 'imageList') {
        if ((this.datas.manualData.length + data.length) >= 50) {
          this.$message.error("选择的商品数据不能超过50条")
          return;
        }
      } else {
        if ((this.datas.commoditylisttypetab[this.number].manualData.length + data.length) >= 50) {
          this.$message.error("选择的商品数据不能超过50条")
          return;
        }
      }
      for (let row of data) {
        if (this.imgText === 'imageList') {
          this.datas.manualData.push(row)
        } else {
          this.datas.commoditylisttypetab[this.number].manualData.push(row)
        }
      }
      this.imgText = null
      this.number = null
    },

    showUpload(type) {
      this.uploadImgDataType = type
      this.$refs.uploadImg.showUpload()
    },
    uploadMultopleInformation(data) {
      if (!this.datas.bannerData) {
        this.$set(this.datas, 'bannerData', []);
      }
      for (let imageUrl of data) {
        this.datas.bannerData.push({
          imageUrl: imageUrl,
          http: {},
        })
      }
    },
    // 背景图
    uploadInformation(res) {
      //-console.log(res, '---------uploadImg')
      if (this.uploadImgDataType === '0') {
        this.datas.bgImg = res
        //-console.log(this.datas.bgImg, '---------uploadImg')
      }
    },

    // 清空背景图片
    clear() {
      this.datas.bgImg = ''
    },

    /* 删除图片 */
    deleteimg(index) {
      this.datas.manualData.splice(index, 1)
    },
    deleteBannerImg(index) {
      this.datas.bannerData.splice(index, 1)
    },
  },
  computed: {
    styleText() {
      let modeType
      if (this.datas.commodityType === 0) modeType = '大图模式'
      if (this.datas.commodityType === 1) modeType = '一行两个'
      if (this.datas.commodityType === 2) modeType = '一行三个'
      if (this.datas.commodityType === 3) modeType = '详细列表'
      if (this.datas.commodityType === 4) modeType = '一大两小'
      if (this.datas.commodityType === 5) modeType = '横向滑动'

      return modeType
    },
  },
}
</script>

<style scoped lang="scss">
.listswitchingstyle {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 10px 20px;
  box-sizing: border-box;

  /* 标题 */
  h2 {
    padding: 24px 16px 24px 0;
    // margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    color: #323233;
  }

  .lef {
    /deep/.el-form-item__label {
      text-align: left;
    }
  }

  .shop-head-pic {
    color: #ababab;
    display: flex;
    flex-direction: column;

    .home-bg {
      width: 100px;
      height: 100px;
      margin: 10px auto;
    }

    .shop-head-pic-btn {
      display: flex;
      flex-direction: row;

      .el-button {
        flex: 1;
      }
    }
  }

  /* 列表样式 */
  .commodityType {
    display: flex;
    justify-content: space-around;
    align-items: center;

    span {
      display: inline-block;
      width: 58px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: #fff;
      border: 1px solid #ebedf0;
      color: #979797;
      margin: 0 1px;
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        border: 1px solid #51CDCB;
        color: #51CDCB;
      }

      &.active {
        border: 1px solid #51CDCB;
        background-color: #51cdcb26;
        color: #51CDCB;
      }
    }
  }

  /* 商品样式 */
  .moditystyle {
    font-size: 12px;
    width: 100%;
    display: flex;

    span {
      width: 86px;
      height: 32px;
      display: inline-block;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      border: 1px solid #ebedf0;

      &.active {
        border: 1px solid #51CDCB;
        background-color: #51cdcb26;
        color: #51CDCB;
      }
    }

    .addMethod {
      width: 110px;
      height: 40px;
      line-height: 40px;
    }
  }

  .moditystyle2 {
    justify-content: center;
  }

  /* 位置 */
  .weiz {
    text-align: right;

    i {
      padding: 5px 14px;
      margin-left: 10px;
      border-radius: 0;
      border: 1px solid #ebedf0;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;

      &:last-child {
        font-size: 22px;
      }

      &.active {
        color: #51CDCB;
        border: 1px solid #51CDCB;
        background: #51cdcb26;
      }
    }
  }

  /* 单选框 */
  /deep/.radi1 {
    border-top: 1px solid #f7f8fa;
    border-bottom: 1px solid #f7f8fa;
    padding: 12px 0;

    .el-radio {
      margin: 10px 25px 7px 0;
    }
  }

  .typeImgBanner {
    padding: 8px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    margin-top: 20px;
    position: relative;

    .el-icon-circle-close {
      position: absolute;
      right: -10px;
      top: -10px;
      cursor: pointer;
      font-size: 19px;
    }
  }

  /* 商品列表 */
  .imgBanner {
    padding: 6px 12px;
    margin: 16px 7px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
    display: flex;
    position: relative;

    /* 删除图标 */
    .el-icon-circle-close {
      position: absolute;
      right: -10px;
      top: -10px;
      cursor: pointer;
      font-size: 19px;
    }

    /* 图片 */
    .imag {
      width: 60px;
      height: 60px;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        display: inline-block;
      }

      span {
        background: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #fff;
        height: 20px;
        line-height: 20px;
      }
    }

    /* 图片字 */
    .imgText {
      width: 80%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding-left: 20px;
      justify-content: space-between;
    }
  }

  /* 上传图片按钮 */
  .uploadImg {
    width: 345px;
    height: 40px;
    margin-top: 20px;
  }

  // 上传弹框内容部分
  /deep/.uploadIMG .el-dialog__body {
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
  }

  .disable {
    /deep/.el-upload {
      display: none !important;
    }
  }

  .tit {

    /deep/.el-input__inner {
      text-align: center;
    }
  }
}
</style>
