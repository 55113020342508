<template>
  <div class="noticestyle">
    <!-- 标题 -->
    <h2>{{ datas.text }}</h2>
    <!-- 公告 -->
    <el-form label-width="90px" :model="datas" :rules="rules" size="small" class="lef">
      <div class="moditystyle moditystyle2">
        <span class="addMethod" :class="datas.addMethod == 'auto' ? 'active' : ''" @click="datas.addMethod = 'auto'">
          自动获取活动
        </span>
        <span class="addMethod" :class="datas.addMethod == 'manual' ? 'active' : ''" @click="datas.addMethod = 'manual'">
          手动添加活动
        </span>
      </div>
      <br>

      <!-- 选择活动 -->
      <el-button v-if="datas.addMethod === 'manual'" @click="showArticleModel()" class="uploadImg" type="primary" plain><i
          class="el-icon-plus" />点击添加文章</el-button>
      <!-- 图片广告 -->
      <div v-if="datas.addMethod === 'manual' && datas.activity[0]">
        <vuedraggable v-model="datas.activity" v-bind="dragOptions">
          <transition-group>
            <section class="imgBanner" v-for="(item, index) in datas.activity" :key="item + index">
              <i class="el-icon-circle-close" @click="deleteimg(index)" />
              <!-- 标题和链接 -->
              <div class="imgText">
                <el-input v-model="item.title" placeholder="活动标题" size="mini" disabled />
              </div>
            </section>
          </transition-group>
        </vuedraggable>
      </div>
      <div class="bor" />
      <el-form-item label="公告标题" :hide-required-asterisk="true" prop="title">
        <el-input v-model="datas.title" maxlength="2" placeholder="请输入公告标题" show-word-limit />
      </el-form-item>
      <div style="height: 10px" />
      <el-form-item label="活动条数" class="lef borrediu" v-if="datas.addMethod === 'auto'">
        <el-slider v-model="datas.dataNumber" @change="changeDataNumber($event)" :max="10" :min="1" input-size="mini"
          show-input>
        </el-slider>
      </el-form-item>
      <div style="height: 10px" v-if="datas.addMethod === 'auto'" />
      <!-- 标题颜色 -->
      <el-form-item label="标题颜色" class="lef">
        <el-color-picker v-model="datas.titleColor" show-alpha class="picke" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>
      <div style="height: 10px" />

      <!-- 背景颜色 -->
      <el-form-item label="背景颜色" class="lef">
        <el-color-picker v-model="datas.backColor" show-alpha class="picke" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>

      <div style="height: 10px" />

      <!-- 文字颜色 -->
      <el-form-item label="文字颜色" class="lef">
        <el-color-picker v-model="datas.textColor" show-alpha class="picke" :predefine="predefineColors">
        </el-color-picker>
      </el-form-item>

      <!-- 标题是否倾斜 -->
      <el-form-item class="lef" label="标题样式">
        {{ datas.isItalic ? '倾斜' : '正常' }}
        <el-checkbox style="margin-left: 196px" v-model="datas.isItalic" />
      </el-form-item>
      <!-- 显示类型 -->
      <!-- <el-form-item class="lef" label="显示类型">
        <el-radio v-model="datas.showType" label="topBottomRoll">上下滚动</el-radio>
        <el-radio v-model="datas.showType" label="leftRightRoll">左右滚动</el-radio>
      </el-form-item> -->
    </el-form>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择文章资讯" :visible.sync="showArticle"
      :destroy-on-close="true" width="80%" center>
      <commonTable :tableData="articleTableData" :loading="articleLoading" @handleSizeChange="articleHandleSizeChange"
        @handleCurrentChange="articleHandleCurrentChange" :currentPage="articleCurrentPage" :total="articleTotal">
        <template v-slot:table>
          <el-table-column width="55">
            <template slot-scope="scope">
              <el-checkbox v-model="articleIds[scope.row.dynamicId]" @change="selectArticle($event, scope.row)"
                :label="scope.row.dynamicId">{{ ''
                }}</el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="photos" align="center" label="封面图" width="120px" required>
            <template slot-scope="scope">
              <el-image v-if="scope.row.photos" style="width: 100px; height: 100px;margin-bottom:-10px"
                :src="scope.row.photos">
              </el-image>
              <p v-else
                style="width: 100px; height: 100px;line-height: 100px;font-size:12px;margin:0 auto;background: #eee;">
                暂无图片
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="title" align="center" label="标题" show-overflow-tooltip />
        </template>
      </commonTable>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消 上 传</el-button>
        <el-button type="primary" @click="submitTable" :disabled="disabl">点 击 上 传</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { selectDynamicList } from "@/api/appModel";
import commonTable from "@/components/common/commonTable";
import vuedraggable from 'vuedraggable' //拖拽组件
export default {
  name: 'crmNoticestyle',
  components: {
    commonTable,
    vuedraggable
  },
  props: {
    datas: Object,
  },
  data() {
    return {
      rules: {
        //校验表单输入
        title: [
          //页面名称
          { required: true, message: '请输入公告提示', trigger: 'blur' },
        ],
      },
      dragOptions: {
        animation: 200,
      },
      predefineColors: [
        // 颜色选择器预设
        "#fff",
        "#000",
        "#3064ba",
        "#d34e4f",
        '#51CDCB',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        '#409EFF',
        '#909399',
        '#C0C4CC',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577',
      ],
      //文章列表配置
      articleIds: [],
      articleTableData: [],
      showArticle: false,//模态框显示状态
      articleCurrentPage: 1, //当前页
      articlePageSize: 10, //显示条数
      articleLoading: false, //表格加载
      articleTotal: 0, //总条数
      checkedarticle: {},//选中的内容
      disabl: false,
      index: 0,
      article: {},//文章数据
      companyId: this.$store.state.loginRoot.userInfo.companyId,
    }
  },
  created() {
    this.articleAllData = this.$store.state.crmDrag.articleAllData;
    this.changeDataNumber(this.datas.dataNumber);
   //-console.log(this.datas.autoData);
  },
  methods: {
    changeDataNumber(dataNumber) {
      if (dataNumber) {
        this.datas.autoData = this.articleAllData.slice(0, dataNumber)
      } else {
        this.datas.autoData = [];
      }
    },
    /**文章配置 */
    /**@method 选中文章 */
    selectArticle(isOpen, data) {
      if (isOpen) {
        this.checkedarticle[data.dynamicId] = data;
      } else {
        delete this.checkedarticle[data.dynamicId];
      }
    },
    /**@method 显示社团文章列表 */
    showArticleModel() {
      this.showArticle = true;
      this.getArticleList();
    },
    /**@method 显示文章列表 */
    showArticleFun(index) {
      this.index = index;
      this.showArticle = true;
    },
    /**@method 文章获取列表 */
    async getArticleList() {
      let params = {
        pageSize: this.articlePageSize,
        pageNum: this.articleCurrentPage,
        type: 4,
        companyId: this.companyId,
        isDrafts: 0
      };
      try {
        this.articleLoading = true;
        let res = await selectDynamicList(params)
        this.articleLoading = false;

        const { data } = res;
        this.articleTableData = data.pageInfo.list;
        this.articleTotal = data.pageInfo.total;
      } catch (error) {
        this.articleLoading = false;
        this.articleTableData = [];
      }
    },
    /**@method 文章切换行 */
    articleHandleSizeChange(val) {
      this.articlePageSize = val;
      this.getArticleList();
    },
    /**@method 文章切换下一页 */
    articleHandleCurrentChange(val) {
      this.articleCurrentPage = val;
      this.getArticleList();
    },
    /**文章配置 */
    // 关闭弹框
    handleClose() {
      this.showArticle = false
    },
    // 提交
    submitTable() {
      this.disabl = true;
      for (let i in this.checkedarticle) {
        let row = this.checkedarticle[i];
        this.datas.activity.push({
          id: row.dynamicId,
          title: row.title,
        })
      }
      this.showArticle = false;
      this.disabl = false;
    },

    /* 删除图片 */
    deleteimg(index) {
      this.datas.activity.splice(index, 1)
    },
  }
}
</script>

<style scoped lang="scss"> /* 商品样式 */
 .moditystyle {
   font-size: 12px;
   width: 100%;
   display: flex;

   span {
     width: 86px;
     height: 32px;
     display: inline-block;
     text-align: center;
     line-height: 32px;
     cursor: pointer;
     border: 1px solid #ebedf0;

     &.active {
       border: 1px solid #51CDCB;
       background-color: #51cdcb26;
       color: #51CDCB;
     }
   }

   .addMethod {
     width: 110px;
     height: 40px;
     line-height: 40px;
   }
 }

 .moditystyle2 {
   justify-content: center;
 }

 .imgBanner {
   padding: 6px 12px;
   margin: 16px 7px;
   border-radius: 2px;
   background-color: #fff;
   box-shadow: 0 0 4px 0 rgba(10, 42, 97, 0.2);
   display: flex;
   position: relative;

   /* 删除图标 */
   .el-icon-circle-close {
     position: absolute;
     right: -10px;
     top: -10px;
     cursor: pointer;
     font-size: 19px;
   }

   /* 图片 */
   .imag {
     width: 60px;
     height: 60px;
     border-radius: 5px;
     overflow: hidden;
     position: relative;
     cursor: pointer;

     img {
       width: 100%;
       height: 100%;
       display: inline-block;
     }

     span {
       background: rgba(0, 0, 0, 0.5);
       font-size: 12px;
       position: absolute;
       left: 0px;
       bottom: 0px;
       display: inline-block;
       width: 100%;
       text-align: center;
       color: #fff;
       height: 20px;
       line-height: 20px;
     }
   }

   /* 图片字 */
   .imgText {
     width: 100%;
     display: flex;
     flex-direction: column;
     box-sizing: border-box;
     justify-content: space-between;

     /* 图片字 */
     .imgTextChild {
       width: 100%;
       display: flex;
       box-sizing: border-box;
       justify-content: space-between;

       .fir-sele.el-select {
         width: 40%;
       }
     }
   }
 }

 .uploadImg {
   width: 345px;
   height: 40px;
   margin-top: 20px;
 }

 .noticestyle {
   width: 100%;
   position: absolute;
   left: 0;
   top: 0;
   padding: 0 10px 20px;
   box-sizing: border-box;

   /* 标题 */
   h2 {
     padding: 24px 16px 24px 0;
     margin-bottom: 15px;
     border-bottom: 1px solid #f2f4f6;
     font-size: 18px;
     font-weight: 600;
     color: #323233;
   }

   .lef {
     /deep/.el-form-item__label {
       text-align: left;
     }
   }

   /* 颜色选择器 */
   .picke {
     float: right;
   }
 }
</style>
