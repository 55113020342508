<template>
  <div>
    <div v-if="isTree">
      <el-table @row-click="clickRow" @current-change="currentChangeFun" :tree-props="treeProps" v-loading="loading"
        ref="multipleTable" :data="tableData" :span-method="spanMethod" border lay tooltip-effect="dark"
        :style="{ marginTop: marginTop }" @selection-change="SelectionChange" :row-key="treeKey"
        @expand-change="expandChange">
        <slot name="table"></slot>
      </el-table>
    </div>
    <div v-else>

      <el-table @row-click="clickRow" @current-change="currentChangeFun" v-loading="loading"
        :height="showSummary ? tableHeight : null" :show-summary="showSummary" ref="multipleTable"
        :summary-method="getSummaries" :data="tableData" :span-method="spanMethod" border lay tooltip-effect="dark"
        :style="{ marginTop: marginTop }" @selection-change="SelectionChange" @expand-change="expandChange">
        <slot name="table"></slot>
      </el-table>
    </div>

    <div style="display: flex; justify-content: flex-end; margin-top: 16px" v-if="tableData.length !== 0">
      <!-- <div style="display: flex; justify-content: flex-end; margin-top: 40px" v-if='tableData.length !== 0 && hideTable == false'> -->

      <el-pagination v-if="paginationVisible" background @size-change="sizeChange" @current-change="currentChange"
        :current-page="currentPage" :page-sizes="pageSizeData" layout="total, sizes, prev, pager, next, jumper" class=""
        :total="total" :pager-count="5" :page-size="pageSize" />
    </div>
  </div>
</template>

<script>
export default {
  name: "commonTable",
  props: {
    isTree: {
      type: Boolean,
      default: false,
    },
    treeKey: {
      type: String,
      default: 'id'
    },
    treeProps: {
      type: Object,
      default: () => {
        return { children: 'children', hasChildren: 'hasChildren' }
      },
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    hideTable: { //显隐表单
      type: Boolean,
      default: false
    },
    showSummary: { //显示合计
      type: Boolean,
      default: false
    },
    spanMethod: {
      type: Function,
      default: () => { },
    },

    total: {
      //总条数
      type: Number,
      default: 0,
    },

    currentPage: {
      //当前页数
      type: Number,
      default: 1,
    },

    loading: {
      //表格加载
      type: Boolean,
      default: false,
    },

    handleSizeChange: {
      //监听切换条数
      type: Function,
      default: () => { },
    },

    handleCurrentChange: {
      type: Function,
      default: () => { },
    },

    handleSelectionChange: {
      type: Function,
      default: () => { },
    },
    handleClickRow: {
      type: Function,
      default: () => { },
    },
    paginationVisible: {
      //表格加载
      type: Boolean,
      default: true,
    },
    clearName: {
      type: String,
      default: '',
    },
    itemKey: {
      type: String,
      default: '',
    },
    marginTop: {
      type: String,
      default: '24px',
    },
    pageSizeData: {
      type: Array,
      default: function () {
        return [10, 15, 20, 50]
      },
    },
    pageSize: {
      type: Number,
      default: function () {
        return 10
      },
    },
  },
  data() {
    return {
      tableHeight: null,
      computedClearName: [
        'demandProtuctsCheck',
      ]
    };
  },
  mounted() {
    this.tableHeight = $(".content") ? $(".content").height() - 300 + 'px' : null;
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
     //-console.log(param);
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return (Number(prev) + Number(curr)).toFixed(2);
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = '-';
        }
      });
      return sums;
    },
    checkedDataFun(data) {
      for (let row of data) {
        this.$refs.multipleTable.toggleRowExpansion(row);
      }
    },
    toggleRowExpansion(row) {
      this.$refs.multipleTable.toggleRowExpansion(row)
    },
    /**@method 监听切换条数并调用父组件方法 */
    sizeChange(val) {
      this.$emit("handleSizeChange", val);
      /*  handleSizeChange(val) {
      //-console.log(`每页 ${val} 条`);
      } */
    },
    /**@method 监听下一页并调用父组件方法 */
    currentChange(val) {
      this.$emit("handleCurrentChange", val);
      /* handleCurrentChange(val) {
        //-console.log(`当前页: ${val}`);
      } */
    },
    expandChange(row, data) {
      this.$emit("expandChange", { row, data: data });
    },
    SelectionChange(val) {
      if (this.computedClearName.includes(this.clearName)) {
        const item = val.find(item => { return item[this.itemKey] != "0" });
        if (item) {
          this.$refs.multipleTable.clearSelection();
          this.$message({
            message: "请选择审核中的需求",
            type: "warning",
          });
          return;
        }
      }
      this.$emit("handleSelectionChange", val);
    },
    clickRow(row) {
      this.$emit("handleClickRow", row);
    },
    currentChangeFun(row) {
      this.$emit("currentChangeFun", row);
    },
  },
};
</script>

<style lang="scss" scoped></style>
